<template>
  <section  :class="`detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title='Quran&Journey Org'  v-show="!back || deviceType==='desktop'" />
    <BackHead :title=title  v-show="back && deviceType!=='desktop'" />
    <div class="content-wrapper">
      <slot />
    </div>
    <FootBar/>
  </section>
</template>

<script>
import BackHead from './back_head.vue'
export default {
  components: {
    BackHead
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Quran&Journey Org'
    },
    back: {
      type: Boolean,
      default: false,
      require: false
    }
  },

};
</script>