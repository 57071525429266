<template>
	<footer>
		<div class="foot-nav-container pc_padding">
			<div class="footer-about">
				<div class="footer-item-title">About us</div>
				<div class="footer-item-content">At Quran Journey, we invite you to delve deeper into the Holy Quran. Our platform offers valuable resources for understanding its teachings and enhancing your spiritual journey. Explore more at quranjourney.org.</div>
			</div>
			<div class="footer-side-row">
				<div class="footer-side-resource">
					<div class="footer-item-title">Resources</div>
					<div class="resource">
						<div
							class="footer-item-content cursor"
							@click="goto('terms')">
							Terms of services
						</div>
						<div
							class="footer-item-content cursor mtc"
							style="margin-top: 12px;"
							@click="goto('privacy')">
							Privacy policy
						</div>
					</div>
				</div>
				<div class="footer-side-contact">
					<div class="footer-item-title">Contact us</div>
					<div class="footer-item-content" style="margin-top: 12px">
						<a
							
							class="footer-item-content"
							href="mailto:quranjourney.support@gmail.com">
							quranjourney.support@gmail.com
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="corner-module pc_padding">
			<br />
			Copyright © 2024 Quran&Journey Org. All Rights Reserved.
		</div>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FootModule1',
	computed: {
		...mapGetters({
			host: 'host',
			deviceType: 'deviceType',
		}),
	},
	methods: {
		goto(where) {
			console.log(this.$route.path)
			this.$router.push({ name: where })
		},
	},
}
</script>
